<script>
import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import appConfig from "../../../app.config";
import Axios from "@/plugins/axios";
import Particles from "@/components/auth/particles.vue";
import Footer from "@/components/footer.vue"
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import store from "@/state/store";
import {formatedUser} from "@/helpers/functions";

export default {
  page: {
    title: "Login",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  components: {Particles, Footer},
  data() {
    return {
      // phoneNumber: "237677777777",
      phoneNumber: "",
      // password: "MCP2024",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      inputType: false
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    inputChange(){
      return this.inputType ? 'text' : 'password'
    }
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,

    async signinapi() {
      this.submitted = true
      try {
        const response = await Axios.post('/auth/login', {
          username: "237" + this.phoneNumber,
          password: this.password.trim()
        });
         this.$cookies.set('jwt', JSON.stringify(response.data))

        const loginUserResponse = await Axios.get('/users/get/logged_user', {
          headers: {
            Authorization: `Bearer ${response.data.token}`
          }
        });
        const user = this.formatedUser(loginUserResponse.data, ['onlinePaymentRequests', 'accounts']);
        
        store.commit('auth/SET_CURRENT_USER', user)
        window.location.reload()
      } catch (error) {
        this.submitted = false
        if(error.response.status === 401){
          this.authError = "Numéro de téléphone ou mot de passe incorrect"
        } else {
          this.authError = error.response.data.detail
        }
      }
    },
    formatedUser
  },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
      <Particles />
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
<!--            <div class="text-center mt-sm-5 mb-4 text-white-50">-->
<!--              <div>-->
<!--                <router-link to="/" class="d-inline-block auth-logo">-->
<!--                  <img src="@/assets/images/logo-light.png" alt="" height="20" />-->
<!--                </router-link>-->
<!--              </div>-->
<!--              <p class="mt-3 fs-15 fw-medium">-->
<!--                Premium Admin & Dashboard Template-->
<!--              </p>-->
<!--            </div>-->
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Bon retour parmis nous !</h5>
                  <p class="text-muted">Connectez-vous pour acceder a MCP.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
                  <form @submit.prevent="signinapi">
                    <div class="mb-3">
                      <label for="telephone" class="form-label">Numéro de téléphone</label>
                      <input required type="tel" class="form-control" id="telephone" placeholder="698745278" v-model="phoneNumber" />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
<!--                      <div class="float-end">-->
<!--                        <router-link to="/forgot-password" class="text-muted">Forgot-->
<!--                          password?</router-link>-->
<!--                      </div>-->
                      <label class="form-label" for="password-input">Mot de passe</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input required :type="inputChange" v-model="password" class="form-control pe-5" placeholder=""
                          id="password-input" />
                        <button @click="inputType = !inputType" class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

<!--                    <div class="form-check">-->
<!--                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />-->
<!--                      <label class="form-check-label" for="auth-remember-check">Remember-->
<!--                        me</label>-->
<!--                    </div>-->

                    <div class="mt-4">
                      <button :disabled="submitted" class="btn btn-success w-100" type="submit">
                        <div v-if="submitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <span v-if="!submitted">Connexion</span>
                      </button>
                    </div>

<!--                    <div class="mt-4 text-center">-->
<!--                      <div class="signin-other-title">-->
<!--                        <h5 class="fs-13 mb-4 title">Sign In with</h5>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <button type="button" class="btn btn-primary btn-icon-->
<!--                          waves-effect waves-light">-->
<!--                          <i class="ri-facebook-fill fs-16"></i>-->
<!--                        </button>-->
<!--                        <button type="button" class="btn btn-danger btn-icon-->
<!--                          waves-effect waves-light-->
<!--                          ms-1">-->
<!--                          <i class="ri-google-fill fs-16"></i>-->
<!--                        </button>-->
<!--                        <button type="button" class="btn btn-dark btn-icon-->
<!--                          waves-effect waves-light-->
<!--                          ms-1">-->
<!--                          <i class="ri-github-fill fs-16"></i>-->
<!--                        </button>-->
<!--                        <button type="button" class="btn btn-info btn-icon-->
<!--                          waves-effect waves-light-->
<!--                          ms-1">-->
<!--                          <i class="ri-twitter-fill fs-16"></i>-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </div>-->
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

<!--            <div class="mt-4 text-center">-->
<!--              <p class="mb-0">-->
<!--                Don't have an account ?-->
<!--                <router-link to="/register" class="fw-semibold text-primary-->
<!--                  text-decoration-underline">-->
<!--                  Signup-->
<!--                </router-link>-->
<!--              </p>-->
<!--            </div>-->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <Footer />
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>